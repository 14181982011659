import {SxProps} from "@mui/system";

export const defaultDialogStyle = (width?: number): SxProps => ({
    "& .MuiDialog-container": {
        "& .MuiPaper-root": width ? {
            width: "100%",
            maxWidth: width + "px",
        } : {},
        '& input.Mui-disabled': {
            color: 'var(--color-black-text)',

        },
        '& h3': {
            margin: 0,
            padding: 0,
            fontSize: '18px',
        }
    }
})
