import {ContentLearningEventShowResponseOrdersInner, OrderUpdate} from "generated-api";
import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {isApiResultError} from "../../helpers/api";
import {Formik, FormikErrors} from "formik";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import 'react-quill/dist/quill.snow.css';
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import {quillGetValue, quillSetValue} from "utils/quill";
import {updateLearningEventOrder} from "store/userGroup";
import {defaultDialogStyle} from "utils/dialog";
import {QuillEditorField} from "components/form/QuillEditorField";

interface ValuesType extends OrderUpdate {
}

const dialogStyle = defaultDialogStyle();

export const EditOrderNoteDialog = ({order, learningEventId, onSuccess, onClose}: {
    order: ContentLearningEventShowResponseOrdersInner,
    learningEventId: number,
    onSuccess: (result: any) => void,
    onClose: () => void
}) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [isSaving, setIsSaving] = useState(false);

    const initialValues: ValuesType = useMemo(() => {
        return {
            ...order,
            note: quillSetValue(order.note)
        }
    }, [order]);

    const handleSave = useCallback(async (values: ValuesType) => {
        setIsSaving(true);

        const res = await dispatch(updateLearningEventOrder({
            id: String(order.id),
            learningEventId,
            body: {
                ...order,
                note: quillGetValue(values.note),
            }
        }));
        if (!isApiResultError(res)) {
            onSuccess(res.payload);
            onClose();
        }
        setIsSaving(false);
    }, [order, learningEventId, dispatch, onSuccess, onClose]);

    const handleValidate = useCallback((values: ValuesType) => {
        let errors = {} as FormikErrors<ValuesType>;
        if (!values.note) {
            // errors.description = t('offer.description.dialog.required')
        }
        return errors;
    }, []);

    return (
        <Dialog open={true} onClose={onClose} PaperComponent={Paper} sx={dialogStyle} maxWidth={'md'}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSave}
                validate={handleValidate}
            >
                {({handleSubmit}) => <form onSubmit={handleSubmit}>
                    <DialogTitle className={'tw-flex tw-w-full tw-justify-between tw-items-center'}>
                        <span>{t('userGroup.order.note')} {order.company_space?.name}</span>
                        <IconButton onClick={onClose} sx={{color: 'inherit'}}><Close/></IconButton>
                    </DialogTitle>
                    <LinearProgress hidden={!isSaving}/>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <QuillEditorField label={t('userGroup.registration.note')} name={'note'}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{padding: '16px'}}>
                        <Button color={'inherit'} onClick={onClose} disabled={isSaving}>
                            {t('form.cancel')}
                        </Button>
                        <Button color={'primary'} variant={'contained'} type={'submit'} disabled={isSaving}>
                            {t('form.save')}
                        </Button>
                    </DialogActions>
                </form>}
            </Formik>
        </Dialog>
    );
};
