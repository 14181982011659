import {
    type ContentInCompanySpaceIndexResponse,
    type ContentManagerUserGroupDashboardContentResponseLearningEvent,
    UserGroupCreate,
    UserGroupCreateLearningEvent,
    UserGroupUpdate
} from "generated-api";
import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {createUserGroup, updateUserGroup} from "store/userGroup";
import {isApiResultError} from "../../helpers/api";
import {Formik, FormikErrors} from "formik";
import {OptionValue} from "model/form";
import {Alert, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Paper} from "@mui/material";
import {TextFormField, TextFormFieldPlain} from "components/form/TextFormField";
import Button from "@mui/material/Button";
import {SelectFormField} from "components/form/SelectFormField";
import {fetchContents} from "store/content";
import {CheckboxFormField} from "components/form/CheckboxFormField";
import {UserSelectFormField} from "components/form/UserSelectFormField";
import {addMessage} from "store/localApp";
import {defaultDialogStyle} from "utils/dialog";

interface UserGroupValuesType extends Partial<UserGroupUpdate>, Partial<UserGroupCreate> {
}

const dialogStyle = defaultDialogStyle(800);

export const UserGroupDialog = ({id, userGroup, onSuccess, onClose}: {
    id?: number,
    userGroup: Partial<UserGroupCreate> & Partial<UserGroupUpdate>,
    onSuccess: (result: any) => void,
    onClose: () => void
}) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    // content autocomplete
    const [isContentLoading, setIsContentLoading] = useState(false);
    const [contentSearch, setContentSearch] = useState('');
    const [contentOptions, setContentOptions] = useState<OptionValue[]>([]);

    const [isSaving, setIsSaving] = useState(false);

    const initialValues: UserGroupValuesType = useMemo(() => {
        const values = {
            ...userGroup,
        }
        if (id) {
            const le = values.learning_event as ContentManagerUserGroupDashboardContentResponseLearningEvent;
            if (values.learning_event && le.coordinators) {
                values.learning_event.coordinator_ids = le.coordinators.map(c => c.id);
            }
        }
        return values;
    }, [id, userGroup]);

    const handleSave = useCallback(async (values: UserGroupValuesType) => {
        setIsSaving(true);

        const body = {...values};

        if (body.kind !== 'blended_learning') {
            delete body.learning_event;
        } else if (body.learning_event) {
            body.learning_event.unlimited_capacity = !!body.learning_event.unlimited_capacity;
            if (body.learning_event.unlimited_capacity) {
                body.learning_event.maximum_capacity = undefined;
            }
        }

        let res;
        if (id) {
            res = await dispatch(updateUserGroup({
                id: '' + id,
                body: {
                    ...body
                }
            }));
        } else {
            res = await dispatch(createUserGroup({
                body: {
                    name: body.name!,
                    kind: body.kind!,
                    ...body,
                }
            }));
        }
        if (!isApiResultError(res)) {
            if (id) {
                dispatch(addMessage({severity: 'success', title: t('userGroup.edit.success')}));
            }
            onSuccess(res.payload);
            onClose();
        }
        setIsSaving(false);
    }, [id, dispatch, onSuccess, onClose, t]);

    const handleValidate = useCallback((values: UserGroupValuesType) => {
        let errors = {} as FormikErrors<UserGroupValuesType>;
        if (!values.kind) {
            errors.kind = t('userGroup.dialog.kind.messages.required');
        }
        if (!values.name) {
            errors.name = t('userGroup.dialog.name.messages.required');
        }
        if (values.kind === 'blended_learning') {
            if (!values.content_version_id && !id) {
                errors.content_version_id = t('userGroup.dialog.blendedContent.messages.required');
            }
            const errorsEvent = {} as FormikErrors<UserGroupCreateLearningEvent>;
            if (!values.learning_event?.form) {
                errorsEvent.form = t('userGroup.dialog.form.messages.required');
            }
            if (!values.learning_event?.location && values.learning_event?.form !== 'online') {
                errorsEvent.location = t('userGroup.dialog.location.messages.required');
            }
            if (!values.learning_event?.unlimited_capacity) {
                if (!values.learning_event?.maximum_capacity) {
                    errorsEvent.maximum_capacity = t('userGroup.dialog.maximumCapacity.messages.required')
                } else {
                    const count = +values.learning_event?.maximum_capacity;
                    if (count < 1) {
                        errorsEvent.maximum_capacity = t('userGroup.dialog.maximumCapacity.messages.min', {count})
                    } else if (Math.round(count) !== count) {
                        errorsEvent.maximum_capacity = t('userGroup.dialog.maximumCapacity.messages.number')
                    }
                }
            }
            if (!values.learning_event?.minimum_capacity) {
                // assume 0
                // errorsEvent.minimum_capacity = t('userGroup.dialog.minimumCapacity.messages.required')
            } else {
                const count = +values.learning_event?.minimum_capacity;
                if (count < 0) {
                    errorsEvent.minimum_capacity = t('userGroup.dialog.minimumCapacity.messages.min', {count})
                } else if (Math.round(count) !== count) {
                    errorsEvent.minimum_capacity = t('userGroup.dialog.minimumCapacity.messages.number')
                } else if (!values.learning_event?.unlimited_capacity
                    && values.learning_event?.maximum_capacity
                    && count > values.learning_event?.maximum_capacity) {
                    errorsEvent.minimum_capacity = t('userGroup.dialog.minimumCapacity.messages.max', {max: values.learning_event?.maximum_capacity})
                }
            }

            if (Object.keys(errorsEvent).length > 0) {
                (errors as any).learning_event = errorsEvent;
            }
        }
        return errors;
    }, [id, t]);

    const handleNewContent = useCallback(() => {
        window.location.href = '#/contentManager';
    }, [])

    useEffect(() => {
        if (id) {
            return;
        }
        setIsContentLoading(true);
        dispatch(fetchContents({
            contentTypes: 'blended_learning',
            ownershipType: 'original',
            perPage: 50,
            sort: "name:asc",
            name: contentSearch || undefined
        })).then((res) => {
            if (!isApiResultError(res)) {
                setContentOptions((res.payload as ContentInCompanySpaceIndexResponse[])
                    .filter(c => !!c.active_version?.id)
                    .map(c => ({value: c.active_version!.id!, label: c.name, params: {content: c}})));
            } else {
                setContentOptions([]);
            }
        }).finally(() => {
            setIsContentLoading(false);
        })
    }, [id, dispatch, contentSearch]);

    const kindOptions: OptionValue[] = useMemo(() => [
        {value: 'blended_learning', label: t('userGroup.kind.blended_learning')},
        {value: 'elearning', label: t('userGroup.kind.elearning')},
    ], [t]);

    const formOptions: OptionValue[] = useMemo(() => [
        {value: 'online', label: t('userGroup.form.online')},
        {value: 'in_person', label: t('userGroup.form.in_person')},
        {value: 'in_person_and_online', label: t('userGroup.form.in_person_and_online')},
    ], [t]);

    const renderContentOption = useCallback((props: React.HTMLAttributes<HTMLLIElement>, option: OptionValue) => {
        const item = option.params!.content as ContentInCompanySpaceIndexResponse;
        return <li {...props} key={'item-' + option.value} title={option.tooltip} style={{maxWidth: 'calc(800px - 48px)'}}>
            <div className="content-to-assign-item tw-flex tw-content-center tw-justify-center tw-items-center tw-h-auto tw-pt-8 tw-pb-8">
                {!!item.promo_image_url && <div className="content-to-assign-image" style={{backgroundImage: 'url(' + item.promo_image_url + ')'}}></div>}
                <div className={'tw-flex tw-flex-col'}>
                    <div className="text-elipsis text-one-line">
                        {item.name}
                    </div>
                    <div className="description cl-black-lighter text-elipsis text-one-line ta-no-styles" dangerouslySetInnerHTML={{__html: item.description}}></div>
                </div>
            </div>
        </li>;
    }, []);

    return (
        <Dialog open={true} onClose={onClose} PaperComponent={Paper} sx={dialogStyle} disableRestoreFocus>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSave}
                validate={handleValidate}
            >
                {({values, handleSubmit}) => {
                    return <form onSubmit={handleSubmit}>
                        <DialogTitle>
                            {id ? t('userGroup.dialog.edit') : t('userGroup.dialog.new')}
                        </DialogTitle>
                        <LinearProgress hidden={!isSaving}/>
                        <DialogContent>
                            <Grid container spacing={1} sx={{
                                minHeight: '150px',
                                'small': {color: 'var(--color-black-text)'}
                            }}>
                                <Grid item xs={12}>
                                    <SelectFormField name={'kind'} options={kindOptions} label={t('userGroup.dialog.kind.label')}
                                        disabled={!!id}
                                    />
                                </Grid>
                                {values.kind === 'elearning' && <>
                                    <Grid item xs={12}>
                                        <TextFormField name={'name'} label={t('userGroup.dialog.name.label')}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CheckboxFormField
                                            name={'self_assignable'}
                                            tooltip={t('userGroup.dialog.selfAssign.tooltip')}
                                            label={t('userGroup.dialog.selfAssign.label')}
                                        />
                                    </Grid>
                                </>}
                                {values.kind === 'blended_learning' && <>
                                    <Grid item xs={12}>
                                        {!id && <Grid container spacing={2}>
                                            <Grid item className={'tw-flex-grow'}>
                                                <SelectFormField
                                                    name={'content_version_id'}
                                                    options={contentOptions}
                                                    renderOption={renderContentOption}
                                                    onSearch={setContentSearch}
                                                    isLoading={isContentLoading}
                                                    label={t('userGroup.dialog.blendedContent.label')}
                                                />
                                            </Grid>
                                            <Grid item className={'tw-flex tw-flex-col tw-gap-8'}>
                                                <small>{t('userGroup.dialog.blendedContent.empty')}</small>
                                                <Button color={'dark' as any} variant={'contained'} onClick={handleNewContent} disabled={isSaving}>
                                                    {t('userGroup.dialog.blendedContent.action')}
                                                </Button>
                                            </Grid>
                                        </Grid>}
                                        {!!id && <TextFormFieldPlain currentValue={(userGroup as any).content_version_name} name={'contentName'} disabled/>}
                                    </Grid>
                                    {!values.content_version_id && !id && <Grid item xs={12}>
                                        <Alert severity={'warning'} icon={false} sx={{'small': {fontSize: 'inherit'}}}>
                                            <div dangerouslySetInnerHTML={{__html: t('userGroup.dialog.blendedContent.hint')}}/>
                                        </Alert>
                                    </Grid>}
                                    {(!!values.content_version_id || id) && <>
                                        <Grid item xs={12}>
                                            <TextFormField name={'name'} label={t('userGroup.dialog.name.label')}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SelectFormField name={'learning_event.form'} options={formOptions} label={t('userGroup.dialog.form.label')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFormField name={'learning_event.location'} label={t('userGroup.dialog.location.label')}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <UserSelectFormField name={'learning_event.coordinator_ids'}
                                                label={t('userGroup.dialog.coordinators.label')}
                                                placeholder={t('userGroup.dialog.coordinators.add')}
                                                filterRole={'admin,owner'}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextFormField name={'learning_event.maximum_capacity'} label={t('userGroup.dialog.maximumCapacity.label')}
                                                disabled={values.learning_event?.unlimited_capacity}
                                                minValue={1} maxValue={9999}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextFormField name={'learning_event.minimum_capacity'} label={t('userGroup.dialog.minimumCapacity.label')}
                                                minValue={0} maxValue={values.learning_event?.unlimited_capacity ? 9999 : (values.learning_event?.maximum_capacity || 1)}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckboxFormField
                                                name={'learning_event.unlimited_capacity'}
                                                label={t('userGroup.dialog.unlimitedCapacity.label')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckboxFormField
                                                name={'self_assignable'}
                                                tooltip={t('userGroup.dialog.selfAssign.tooltip')}
                                                label={t('userGroup.dialog.selfAssign.label')}
                                            />
                                        </Grid>
                                    </>}
                                </>}
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{padding: '16px'}}>
                            <Button color={'inherit'} onClick={onClose} disabled={isSaving}>
                                {t('form.cancel')}
                            </Button>
                            <Button color={'primary'} variant={'contained'} type={'submit'} disabled={isSaving}>
                                {id ? t('userGroup.dialog.update') : t('userGroup.dialog.create')}
                            </Button>
                        </DialogActions>
                    </form>
                }}
            </Formik>
        </Dialog>
    );
};
