import * as React from "react";
import {useCallback, useEffect, useMemo, useRef} from "react";
import {Box, FormLabel} from "@mui/material";
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useFormikContext} from "formik";
import {FormFieldProps} from "model/form";

interface Props extends FormFieldProps {
}

const formats = [
    'header',
    'code-block', 'bold', 'italic', 'underline',
    'list', 'bullet', 'indent',
    'link'
];

export const QuillEditorField = (props: Props) => {
    const {name, label, onChange, onBlur, disabled, readonly} = props;

    const quillEditor = useRef<any>();

    const {values, setFieldValue} = useFormikContext();

    const handleChange = useCallback((value: string) => {
        setFieldValue(name, value, true);
        if (onChange) {
            onChange(value);
        }
    }, [name, onChange, setFieldValue]);

    const handleBlur = useCallback(() => {
        if (onBlur) {
            onBlur(undefined);
        }
    }, [onBlur]);

    const handlers = useMemo(() => ({
        undo: () => {
            return quillEditor.current?.history.undo();
        },
        redo: () => {
            return quillEditor.current?.history.redo();
        },
    }), []);

    const modules = useMemo(() => ({
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true
        },
        toolbar: {
            container: [
                [{'header': 2}, {'header': 3}, {'header': ''}],
                ['code-block', 'bold', 'italic', 'underline'],
                [{'list': 'bullet'}, {'list': 'ordered'}/*, {'indent': '-1'}, {'indent': '+1'}*/],
                ['link'],
                ['undo', 'redo']
            ],
            handlers
        },
        clipboard: {
            matchVisual: false
        }
    }), [handlers]);

    useEffect(() => {
        const icons = Quill.import("ui/icons");
        if (icons["undo"]) {
            return;
        }
        icons['header'] = {
            ...icons['header'],
            '3': icons['header']['2'],
            '2': icons['header']['1']
        };
        icons["undo"] = '<svg viewBox="0 0 18 18">' +
            '    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />' +
            '    <path' +
            '      class="ql-stroke"' +
            '      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"' +
            '    />' +
            '  </svg>';
        icons["redo"] = '<svg viewBox="0 0 18 18">' +
            '    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />' +
            '    <path' +
            '      class="ql-stroke"' +
            '      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"' +
            '    />' +
            '  </svg>';

    }, []);

    return <Box sx={{
        '& .MuiFormLabel-root': {
            color: 'var(--color-black-text)',
            fontSize: '14px'
        },
        '& .ql-editor': {
            minHeight: '250px',
            maxHeight: 'calc(100vh - 320px)',
            overflow: 'auto',
            '& p': {
                padding: '4px 0'
            }
        },
    }}>
        <FormLabel>{label}</FormLabel>
        <ReactQuill theme="snow"
            ref={(el) => {
                if (el) {
                    quillEditor.current = el.getEditor();
                }
            }}
            value={(values as any)[name]}
            readOnly={disabled || readonly}
            modules={modules}
            formats={formats}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    </Box>;
}
