import {BulkSendInvitationResponseInvitedUsersInner, ParticipantUpdate} from "generated-api";
import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {isApiResultError} from "../../helpers/api";
import {Formik, FormikErrors} from "formik";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import 'react-quill/dist/quill.snow.css';
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import {quillGetValue, quillSetValue} from "utils/quill";
import {updateLearningEventParticipant} from "store/userGroup";
import {defaultDialogStyle} from "utils/dialog";
import {QuillEditorField} from "components/form/QuillEditorField";

interface ValuesType extends ParticipantUpdate {
}

const dialogStyle = defaultDialogStyle();

export const EditParticipantNoteDialog = ({participant, learningEventId, onSuccess, onClose}: {
    participant: BulkSendInvitationResponseInvitedUsersInner,
    learningEventId: number,
    onSuccess: (result: any) => void,
    onClose: () => void
}) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [isSaving, setIsSaving] = useState(false);

    const initialValues: ValuesType = useMemo(() => {
        return {
            ...participant,
            note: quillSetValue(participant.note)
        }
    }, [participant]);


    const handleSave = useCallback(async (values: ValuesType) => {
        setIsSaving(true);

        const res = await dispatch(updateLearningEventParticipant({
            id: String(participant.user_in_company_space_id),
            learningEventId,
            body: {
                ...participant,
                note: quillGetValue(values.note),
            }
        }));
        if (!isApiResultError(res)) {
            onSuccess(res.payload);
            onClose();
        }
        setIsSaving(false);
    }, [participant, learningEventId, dispatch, onSuccess, onClose]);

    const handleValidate = useCallback((values: ValuesType) => {
        let errors = {} as FormikErrors<ValuesType>;
        if (!values.note) {
            // errors.description = t('offer.description.dialog.required')
        }
        return errors;
    }, []);

    return (
        <Dialog open={true} onClose={onClose} PaperComponent={Paper} sx={dialogStyle} maxWidth={'md'}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSave}
                validate={handleValidate}
            >
                {({handleSubmit}) => {
                    return <form onSubmit={handleSubmit}>
                        <DialogTitle className={'tw-flex tw-w-full tw-justify-between tw-items-center'}>
                            <span>{t('userGroup.participant.note')} {participant.full_name}</span>
                            <IconButton onClick={onClose} sx={{color: 'inherit'}}><Close/></IconButton>
                        </DialogTitle>
                        <LinearProgress hidden={!isSaving}/>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <QuillEditorField label={t('userGroup.registration.note')} name={'note'}/>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{padding: '16px'}}>
                            <Button color={'inherit'} onClick={onClose} disabled={isSaving}>
                                {t('form.cancel')}
                            </Button>
                            <Button color={'primary'} variant={'contained'} type={'submit'} disabled={isSaving}>
                                {t('form.save')}
                            </Button>
                        </DialogActions>
                    </form>
                }}
            </Formik>
        </Dialog>
    );
};
